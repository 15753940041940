import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import NestedLayout from '../components/nestedLayout';
import Auth from '../models/auth.model';
import { useRouter } from 'next/router';
import { Spinner } from 'react-bootstrap';
import { signIn } from 'next-auth/react';
import { useCookies } from 'react-cookie';
const Page = function () {
    const [cookies, setCookie, removeCookie] = useCookies(['delta_cookie']);
    const [showLoader, setShowLoader] = useState(false);
    const router = useRouter()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError
    } = useForm();

    const onSubmit = handleSubmit(async (data, event) => {
        const formData = new FormData(event.target);
        setShowLoader(true);
        await Auth.login(formData).then((response) => {
            let user=response.data.data;
            user.email=response.data.access_token;
            user.callbackUrl = router.query?.callbackUrl || "/";
            user.redirect = false;
            signIn('credentials', user).then((res) => {
                console.log(res,user);
                window.localStorage.setItem('access_token', response.data.access_token);
                 setCookie('access_token', response.data.access_token, { maxAge: 60 * 60 * 24 * 30, secure: true });
                router.replace(router.query?.callbackUrl || '/');
            });
        }).catch(error => {
            setError('mobile', { type: 'required', message: error.response?.data?.errors?.mobile })
        });
        setShowLoader(false);
    });

    return (

        <div className="container h-100 d-flex align-items-center">

            {showLoader && <div className="bg-loader"><Spinner size="lg" title="Loading..." /></div>}
            <div className="col-md-4 col-12 mx-auto my-md-5">

                <form onSubmit={onSubmit} className="card border border-5">
                    <div className='card-header text-center bg-dark'>
                        <div><h1><i className="bi bi-key text-light"></i></h1></div>
                        <h3 className='text-light'>Admin Panel</h3>
                    </div>
                    <div className="card-body">
                        <div>
                            <label className="form-label" htmlFor="form2Example1"><b className='form-control-sm'>Mobile :</b></label>
                            <input type="number" id="form2Example1" name='mobile' className={`form-control ${errors?.mobile && "is-invalid"}`} {...register('mobile', { required: "Mobile number required" })} />
                            <div className="invalid-feedback">
                                {errors.mobile && errors.mobile.message}
                            </div>
                        </div>

                        <div className="">
                            <label className="form-label" htmlFor="form2Example2"><b className='form-control-sm'>Password :</b></label>
                            <input type="password" id="form2Example2" className={`form-control ${errors?.password && "is-invalid"}`}{...register('password', { required: "password required" })} />
                            <div className="invalid-feedback">
                                {errors.password && errors.password.message}
                            </div>
                        </div>

                    </div>
                    <div className='card-footer'>

                        <button type="submit" className="btn btn-primary border-0" style={{ backgroundColor: "#A2A4A4" }} ><i className="bi bi-box-arrow-in-right" /> Login</button>
                    </div>

                </form>
            </div>
        </div>

    )
}
export default Page;

Page.getLayout = function getLayout(Page) {
    return (
        <NestedLayout>{Page}</NestedLayout>
    )
}


